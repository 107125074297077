<template>
  <div
    id="press-release-detail"
    v-if="pressRelease"
  >
    <div class="heading text-6xl font-semibold">
      {{pressRelease.title}}
    </div>
    <div class="text-3xl my-12">{{pressRelease.created_at}}</div>
    <img :src="pressRelease.image" />

    <div class="press-release-detail">
      <div class="desc text-2xl mt-20 text-justify">
        <p
          v-for="paragraph in pressRelease.content"
          :key="paragraph"
        >{{paragraph}}</p>
      </div>
    </div>
  </div>
</template>

<script src="./press-release-detail.page.js"></script>
<style src="./press-release-detail.page.css" scoped lang="css"></style>
