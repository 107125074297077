import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Press Release",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter()
    const pressRelease = ref(null);

    const updatePageTitle = (name) => {
      document.title = `${name} | NASIDA Latest Press Releases`;
    };

    onMounted(() => {
      store
        .dispatch("getPressRelease", route.params.id)
        .then((_pressRelease) => {
          pressRelease.value = _pressRelease;
          updatePageTitle(pressRelease.value.title);
        }).catch(() => {
          router.push({name: 'Error'})
        });
    });

    return {
      pressRelease,
    };
  },
});
